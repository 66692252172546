        //commonPart.scss
        header {

            .container-fluid,
            .container-xxxxl,
            .container-xxxxxl {
                padding-right: $app-main-pl !important;
            }

            .fas,
            .far {
                margin-right: 0.867em;
            }

            &:not(.componentList) {
                &.search-expanded {
                    padding-bottom: 1em;

                    @include mq('sm') {
                        padding-bottom: 3.750em;
                    }
                }

                padding-top: 1em;

                &:not(.no-alert) {
                    @include mq('xs') {
                        padding-bottom: 1em;
                    }

                    padding-bottom: .75em;

                    @include mq('lg') {
                        padding-bottom: .75em;
                    }
                }

                &.manual-publication {
                    padding-bottom: 3em;

                    .manage-ebox {
                        flex-basis: 100%;
                    }
                }

                @include mq('md') {
                    padding-bottom: .75em;
                }

                @include mq('lg') {
                    &.manual-publication {
                        padding-bottom: 1.75em;

                        .manage-ebox {
                            flex-basis: inherit;
                        }
                    }
                }

                background: $gradientDefault;
                background: -moz-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                background: -webkit-linear-gradient(left, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                background: linear-gradient(to right, $gradientStart 1%, $gradientIntermediate 49%, $gradientStop 100%);
                filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#{$gradientStart}', endColorstr='#{$gradientStop}', GradientType=1)";
            }

            padding-bottom: 1em;

            &.componentList {
                padding-bottom: 0em;
                padding-top: 0em;

                @include mq('sm') {
                    padding-bottom: .25em;
                }

                @include mq('md') {
                    padding-bottom: 1.75em;
                }

                p {
                    margin-bottom: 0em;
                }
            }

            padding-top: .5em;

            h1 {
                margin-top: 0em;
            }

            #logo {
                border-right: none;
                padding-right: 15px;
                padding-left: 15px;

                @include mq('lg') {
                    padding-right: 0em;
                    padding-left: 1em;
                }

                @include mq('xxxxxl') {
                    padding-left: 4em;
                }

                a span {
                    color: $blackFont;
                }
            }

            #appDenomination {
                p {
                    font-size: em(16, $font-size-base);
                    font-family: OpenSansRegular;
                    margin-bottom: 0em;

                    &.enterpriseName {
                        font-family: OpenSansSemibold;
                        font-size: em(30, $font-size-base);
                    }

                    color: #fff;
                }

                padding-bottom: 0;
                padding-top: 0;
                padding-left:2em;
            }

            #tools {
                nav.navbar {
                    .fa-search {
                        font-size: 1.2em;
                    }

                    ul.navbar-nav {
                        -ms-flex-direction:inherit;
                        flex-direction: inherit;
                        flex-wrap: wrap;
                        align-items: flex-end;
                        justify-content: flex-end;
                    }

                    li.separator {
                        &-left span {
                            @include mq('lg') {
                                border-left: 1px solid #fff;
                                padding-left: 1.833em;
                                line-height: 1em;
                            }
                        }

                        &-right a {
                            border: 0px;
                            padding-right: 0;

                            @include mq('xl') {
                                border-right: 1px solid #fff;
                                padding-right: 2em;
                            }
                        }
                    }

                    li.nav-item {
                        line-height: 1.8em;

                        @include mq('md') {
                            text-align: right;
                        }

                        @include mq('lg') {
                            text-align: left;
                        }

                        margin-left: 1.812em;

                        &.logOut {
                            margin-left: 0.917em;
                        }

                        &:first-child {
                            margin-left: 0;
                            flex-grow: 1;
                            text-align: right;
                        }
                    }

                    a,
                    .infoUser {
                        &:not(a) {
                            font-family: OpenSansBold;
                            margin-left: 0em;
                            white-space: nowrap;

                            /*flex-grow: 1;*/
                            @include mq('lg') {
                                margin-left: 2em;
                            }

                            .fa-user {
                                color: $green;
                            }
                        }

                        &:not(.infoUser) {
                            text-decoration: underline;
                        }

                        color: #fff;
                        font-size: $font-size-normal;
                    }

                    .logOut {
                        a {
                            padding-left: 0.833em;
                        }
                    }

                    #lang {
                        a {
                            text-transform: uppercase;
                            text-decoration: none;
                            padding-bottom: 0em;
                        }

                        .nav-link {
                            padding-top: 0;

                            &.dropdown-toggle[aria-expanded=true]:after {
                                content: unicode("f106");
                            }

                            &.dropdown-toggle:after {
                                content: unicode("f107");
                                font-weight: 900;
                                font-family: 'Font Awesome 6 Free';
                                border: 0;
                                vertical-align: inherit;
                                /* margin-right: 0.929em;*/
                            }
                        }

                        &.show.dropdown {
                            position: inherit;
                        }

                        .dropdown-menu {
                            border-radius: 0;
                            border: 0;
                            right: 0px;
                            left: 0px;
                            margin-top: 1em;
                            padding: 0;
                            box-shadow: 0px 6px 16px -4px $shadow;
                            float: right;
                            font-size: 1em;

                            @include mq('sm') {
                                left: auto;
                                right: 0rem;
                                position: absolute;
                                margin-top: 0;
                                min-width: 0 !important;

                                .dropdown-item {
                                    padding-left: 0.5em;
                                    padding-right: .5em;
                                    cursor: pointer;
                                }
                            }

                            a {
                                color: $primary;
                            }
                        }
                    }
                }
            }

            .navigationMenuParams {
                $ps-navMenu-params: 2.067em;
                $pe-navMenu-params: 1.467em;

                padding-left: 15px;
                padding-right: 15px;
                flex-shrink: initial;
                width: auto;
                .navbar-toggler{
                    font-size: 1.171875rem;
                }

                .nav-content {
                    position: absolute;
                    background: #fff;
                    display: block;
                    z-index: 10000;
                    min-width: 50vh;
                    float: right;
                    right: 100%;

                    @include mq ('xs') {
                        right: 10px;
                        min-width: 300px;
                    }

                    top: 7px;
                    border-radius: 4px;
                    padding: 1.133em $pe-navMenu-params 1.667em $ps-navMenu-params;

                    &-header {
                        .close-dropdown {
                            button {
                                color: $blue;
                                font-size: 1.467em;
                            }
                        }

                        .data-info p {
                            font-size: 1em;
                            font-family: 'OpenSansBold';
                            margin-bottom: 0em;
                            line-height: 1.600em;
                        }
                    }
                }

                .navbar-nav {
                    margin-top: 1.600em;
                    margin-left:-#{$ps-navMenu-params};
                    margin-right:-#{$pe-navMenu-params};

                    app-leftnav {
                        display: block;

                        .btn.btn-link {
                            margin-bottom: 1.600em;

                            @include mq('sm') {
                                margin-left: 2.067em !important;
                            }
                        }

                        &>a {
                            margin-left: #{$ps-navMenu-params};

                            i {
                                width: auto;
                                text-align: left;
                            }
                        }

                        li {
                            padding-left: #{$ps-navMenu-params};
                            padding-right: #{$pe-navMenu-params};

                            .nav-link {
                                padding-bottom: 1.267em;
                                padding-top: 1.333em;
                            }
                        }
                    }

                    >li {
                        line-height: 1em;

                        &.active,
                        &.active a {
                            color: $primary;
                        }

                        a i {
                            width: 25px;
                            text-align: right;
                        }

                        &:not(:first-child) {

                            &.active,
                            &.active a {
                                color: $primary;
                            }

                            padding-top: 1em;

                            &:not(.nav-lang),
                            &:not(.logout) {
                                padding-left: #{$ps-navMenu-params};
                                padding-right: #{$pe-navMenu-params};
                            }

                            &.nav-lang ul {
                                width: auto;
                                text-align: center;

                                li {
                                    padding-left: 2em;
                                    padding-bottom: 0.429em;
                                    padding-top: 0.214em;
                                    margin-right: 0em;

                                    &:first-child {
                                        padding-left: 0em;
                                    }
                                }

                                a {
                                    text-transform: uppercase;
                                    line-height: 2em;
                                    font-size: 0.933em;
                                    font-family: OpenSansRegular;
                                    color: $blackLinkFont;

                                    &.active {
                                        color: $primary;
                                        font-family: OpenSansBold;
                                    }
                                }
                            }

                            &.logout {
                                a {
                                    text-decoration: underline;
                                    font-family: OpenSansRegular;
                                }
                            }

                            a {
                                line-height: 2em;
                                font-family: OpenSansSemiBold;
                                color: $blackLinkFont;

                                i {
                                    font-size: 1em;
                                    color: $primary;
                                }
                            }
                        }
                    }
                }

                .fa-bars {
                    font-size: 1.333em;
                }

                &.show.dropdown {
                    position: static;

                    @include mq('sm') {
                        position: relative;
                    }
                }

                .dropdown-toggle {
                    padding-right: 0.25em;

                    &:after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    left: 0px;
                    margin-top: 0em;
                    box-shadow: 0px 6px 16px -4px $shadow;
                    transform: none !important;
                    /*position: relative !important;*/
                    will-change: inherit !important;
                    width: 100%;
                    font-size: 1em;
                    position: fixed;
                }

                .subnav-item {
                    margin: 0;
                    padding: 0;
                    border-bottom: 1px solid $grayLine;
                }

                .languageNav li {
                    padding: 0;
                    font-size: $font-size-normal;
                    text-transform: uppercase;
                    border-bottom: 1px solid $grayLine;

                    a {
                        display: block;
                        width: 100%;
                        padding: .834em .583em;
                    }

                    &:last-child {
                        border-bottom: 0px;
                    }

                    &.active:after {
                        content: unicode("f00c");
                        font: normal normal 900 15px/1 'Font Awesome 6 Free';
                        float: right;
                        padding-right: .583em;
                    }
                }

                /*.nav-item {
                    a {
                        padding: .834em .583em;
                        color: $blackFont;
                        display: block;
                    }
                    background-color: $grayLight;
                    &.external-link {
                        background-color: #fff;
                    }
                    color:$blackFont;
                    font-size: 1.250em;
                    padding:0;
                    border-bottom: 1px solid $grayLine;
                    &:last-child {
                        border-bottom: 0em;
                    }
                    &:not(.infoUser):focus,
                    &:not(.infoUser):active {
                        background-color: $primary;
                        color: #fff;
                    }
                }*/
            }
        }

        app-filter {
            @include clearfix;
            @include padding($top: em(21, $font-size-base), $bottom: em(21, $font-size-base));
            background-color: $grayLighter;
        }

        footer {
            background-color: $blackFont;
            color: #fff;
            height: 13.333em;
            padding-top: 1.250em;

            .container {
                padding-top: 1em;
            }

            ul.list-inline>li {
                &::before {
                    content: " | ";
                    padding-right: .6em;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0
                }

                &:first-child::before,
                &:last-child::before {
                    content: "";
                }
            }

            #content & {
                border-top: 1px solid $blackFont;

                ul {
                    margin-bottom: 0em;
                }
            }
        }

        #content {
            padding-bottom: 1em;

            .row {
                article {
                    padding-bottom: 1.5em;
                    padding-top: 1.5em;
                    background: #fff;
                    min-height: 25em;
                }
            }

            /*.btn.btn-primary {
        background: $blueFont;
        color: #fff;
        text-decoration: underline;
        border-color: transparent;
        border-radius: 0px;
        font-size: 1em;
        padding: 6px;
    }*/
        }

        .badge-light-green {
            background-color: rgba($primary, 0.1);
            font-size: em(14, 15);
        }